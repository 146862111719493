import React from "react"
import Layout from "./src/components/layout"

import { AnimateSharedLayout } from 'framer-motion'

export const wrapPageElement = ({ element, props }) => {
  return (
    <AnimateSharedLayout>
      <Layout {...props}>
        {element}
      </Layout>
    </AnimateSharedLayout>
  )
}