import React, { Fragment, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion'

import Link from './link'
import SelectLanguage from './select-language'
import { slugify } from '../utils'
import useScrollDirecton from '../utils/useScrollDirection'

import Logo from '../img/brand.svg'
import Photo3 from '../img/photos/photo-3.jpg'
import { useWindowSize } from '../utils/useWindowSize'
import { FormattedMessage, useIntl } from 'react-intl'

const Wrapper = ({ isMobile, children, ...props }) => isMobile
  ? <motion.div {...props}>{children}</motion.div>
  : <div {...props}>{children}</div>

const NavBar = ({ links }) => {

  const data = useStaticQuery(query)
  const contentfulPages = [...data.allContentfulPagePage.nodes, ...data.allContentfulPageTextContent.nodes]

  const { locale } = useIntl()
  const homeLink = `/${locale}`;

  const [isCollapsed, setIsCollapsed] = useState(true)
  const toggle = () => setIsCollapsed(!isCollapsed)
  const close = () => setIsCollapsed(true)

  let direction = useScrollDirecton({ thresholdPixels: 50, initialDirection: "up" })
  if (!isCollapsed) {
    direction = "up"
  }

  const { width } = useWindowSize()
  const isMobile = width < 992

  const navOrder = [
    'ndFbV7k3DpyqdnJWTevLS', 	// Startseite
    '1yixIpzo5BJVXIgps9Agi9', 	// Unternehmen
    '1cldTIcfQ0784Yp8U4dgBs', 	// Leistungen
    {
      de: {
        slug: "/de/blog",
        name: "Blog"
      },
      en: {
        slug: "/en/blog",
        name: "Blog"
      }
    },
    'WDxTdHnPs9wvw6cx1rYkt',  // FAQ
    {
      de: {
        slug: "/de/kontakt",
        name: "Kontakt"
      },
      en: {
        slug: "/en/contact",
        name: "Contact"
      }
    }
  ]

  const navOrderServices = [
    "20Bo38ta2x0afkv3Oo68SQ",
    "7yTEPwCjCizo5fccZimBCF",
    "6vYssnoV9veMRIQO06n4Xl",
    "6DFpLyc7zsz0T64uXyU7iT",
    "3DXGPloexxO19qHndNh66Y"
  ]

  const processNavElements = unprocessedArray => unprocessedArray.reduce((nav, el) => {
    if (typeof el === "object") {
      nav.push(el[locale])
    } else if (typeof el === "string") {
      const page = contentfulPages.filter(page => page.node_locale === locale && page.contentful_id === el)[0]
      nav.push({
        slug: `${slugify(locale)}${slugify(page.slug)}`,
        title: page.title,
        id: page.id,
        name: page.name || page.title
      })
      if (el === "1cldTIcfQ0784Yp8U4dgBs") {
        nav[nav.length - 1].subpages = processNavElements(navOrderServices)
      }
    }
    return nav
  }, [])

  // combine hard-coded links and contentful-links to a new array, each filtered to the current locale
  const navData = processNavElements(navOrder)

  return (
    <>
      <nav className={`navbar navbar-expand-lg navbar-light fixed-top scroll-${direction}`}>

        <div className="container-fluid">

          {/*--- Brand ---*/}
          <Link className="navbar-brand" to={homeLink}>
            <img src={Logo} className="navbar-brand-img" alt="Est8 Services" />
          </Link>

          {/*--- Toggler ---*/}
          <button
            className={isCollapsed ? 'navbar-toggler collapsed' : 'navbar-toggler'}
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggle}
          >
            <i className="fe fe-menu h2" />
            {/* <span className="navbar-toggler-icon"></span> */}
          </button>

          {/* Screen darken / Scroll catch / Click-Outside */}
          <AnimatePresence>
            {!isCollapsed && (
              <motion.div
                className="screen-cover position-fixed fixed-top bottom-0 w-100"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={close}
              />
            )}
          </AnimatePresence>

          {/*--- Collapse ---*/}
          <Wrapper
            isMobile={isMobile}
            className="navbar-collapse"
            id="navbarCollapse"
            variants={{
              closed: { x: "100%" },
              open: { x: 0 }
            }}
            animate={
              isCollapsed
                ? "closed"
                : "open"
            }
            initial="closed"
            transition={{
              type: "tween",
              ease: [0.7, 0, 0.3, 1],
              duration: .5
            }}
          >

            {/*--- Toggler ---*/}
            <button
              className={`navbar-toggler navbar-close-btn ${isCollapsed ? '' : 'show'}`}
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={close}
            >
              <i className="fe fe-x h2" />
            </button>

            {/*--- Navigation ---*/}
            <ul className="navbar-nav mx-0 mx-lg-auto" role="navigation">
              {navData.map(link => (
                <Fragment key={`${link.slug}-nav`}>
                  {link.subpages
                    ? (
                      <NavDropdown
                        link={link}
                        lang={locale}
                        handleClick={close}
                        showTeaser
                      />
                    )
                    : (
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          activeClassName="nav-link-active"
                          id="navbarAccount"
                          to={link.slug}
                          alt={link.title}
                          onClick={close}
                          onKeyDown={() => console.log("keydown")}
                        >
                          {link.name}
                        </Link>
                      </li>
                    )
                  }
                </Fragment>
              ))}
            </ul>

            <SelectLanguage
              links={links}
              onClick={close}
              onKeyDown={() => console.log("keydown")}
            />

            {/*--- Button ---*/}
            <Link className="navbar-btn btn btn-sm btn-primary mt-0 lift ml-lg-4" to="https://portal.est8.services" target="_self" rel="nofollow">
              Login
					</Link>
          </Wrapper>

        </div>
      </nav>
    </>
  )
}


const FlyoutTeaser = ({ title, slug }) => (
  <div className="col-12 col-lg-6">
    <div className="dropdown-img-left" style={{ backgroundImage: `url(${Photo3})` }}>
      <h4 className="font-weight-bold text-white mb-0">
        <FormattedMessage id="servicesFlyoutTeaserHeadline" />
      </h4>
      <p className="font-size-sm text-white">
        <FormattedMessage id="servicesFlyoutTeaserSubline" />
      </p>
      <Link to={slug} className="btn btn-sm btn-white shadow-dark fonFt-size-sm">
        {title}
      </Link>
    </div>
  </div>
)


const NavDropdown = ({ link, showTeaser = false, handleClick }) => {
  const [show, setShow] = useState(false)

  const title = link.name
  const data = link.subpages
  const overviewLink = link.slug

  return (
    <li
      role="presentation"
      className={`nav-item dropdown hovered ${show ? "show" : ""}`}
      onMouseEnter={() => { setShow(true) }}
      onFocus={() => { setShow(true) }}
      onBlur={() => { setShow(false) }}
      onMouseLeave={() => { setShow(false) }}
      onClick={() => setShow(false)}
      onKeyDown={() => console.log("keydown")}
    >
      {/* { Pointer action: none in CSS entfernen für mobile} */}
      <Link className="nav-link dropdown-toggle" id="navbarLandings" data-toggle="dropdown" to={overviewLink} aria-haspopup="true" aria-expanded={show} onClick={handleClick} >
        {title} <span className="ml-1 d-none d-lg-inline" aria-hidden>▾</span>
      </Link>
      <div className={`dropdown-menu p-0 ${showTeaser && "dropdown-menu-xl"} ${show ? "show" : ""}`} aria-labelledby="navbarLandings">
        <div className="row no-gutters">
          {showTeaser && <FlyoutTeaser title={title} slug={overviewLink} />}

          <div className="col-12 col-lg-6">

            <div className="dropdown-body" role="presentation" onClick={handleClick} onKeyDown={() => console.log("keydown")}>
              <div className="row no-gutters">
                <div className="col-6">

                  {/*--- Heading ---*/}
                  <h6 className="dropdown-header d-none d-lg-block">
                    {title}
                  </h6>

                  {/*--- List ---*/}
                  {data.map(page => (
                    <Link key={`${page.id}-nav-dropdown`} className="dropdown-item" to={page.slug}>
                      {page.name}
                    </Link>
                  ))}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </li >
  )
}

const query = graphql`
    query {
      allContentfulPagePage(filter: {
        contentful_id: {
          in: [
            "ndFbV7k3DpyqdnJWTevLS",
            "1yixIpzo5BJVXIgps9Agi9",
            "1cldTIcfQ0784Yp8U4dgBs",
            "3DXGPloexxO19qHndNh66Y",
            "6DFpLyc7zsz0T64uXyU7iT",
            "6vYssnoV9veMRIQO06n4Xl",
            "7yTEPwCjCizo5fccZimBCF",
            "20Bo38ta2x0afkv3Oo68SQ",
          ]
        }
      }) {
        nodes {
          contentful_id
          slug
          node_locale
          name
          title
          id
        }
      }
      allContentfulPageTextContent(filter: {
        contentful_id: {in: [
          "WDxTdHnPs9wvw6cx1rYkt"
        ]}}) {
        nodes {
          contentful_id
          slug
          node_locale
          name
          title
          id
        }
      }
    }
  `

export default NavBar