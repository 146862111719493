import React from 'react'
import { Link as GatsbyLink } from "gatsby"
import { slugify, validateExternalLink } from '../utils'

const Link = ({ children, to, activeClassName, partiallyActive, ...other }) => {
  // Use regular <a> tag for external links
  if (validateExternalLink(to)) {
    return <a href={to} data-external-link {...other}>{children}</a>
  }

  // or use Gatsby Link for internal links
  return (
    <GatsbyLink
      to={slugify(to)}
      activeClassName={activeClassName}
      partiallyActive={partiallyActive}
      {...other}
    >
      {children}
    </GatsbyLink>
  )
}

export default Link