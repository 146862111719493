import React from 'react'

const Icon = ({ id, ...props }) => {

  let g = <></>

  //console.log("icon id: ", id.toLowerCase())

  switch (id.toLowerCase()) {
    case "facebook":
      g = (<g>
        <path d="M22.7 0H1.3C.6 0 0 .6 0 1.3v21.4c0 .7.6 1.3 1.3 1.3h11.5v-9.3H9.7v-3.6h3.1V8.4c0-3 2-4.8 4.7-4.8l2.8.2V7h-2c-1.4 0-1.7.7-1.7 1.8V11H20l-.4 3.6h-3.1V24h6c.8 0 1.4-.6 1.4-1.3V1.3c0-.7-.6-1.3-1.3-1.3z" fill="currentColor" />
      </g>)
      break;
    case "instagram":
      g = (<g>
        <path d="M12 2.16c3.2 0 3.58.01 4.85.07 3.25.15 4.77 1.7 4.92 4.92.06 1.27.07 1.65.07 4.85 0 3.2-.01 3.59-.07 4.85-.15 3.22-1.66 4.77-4.92 4.92-1.27.06-1.64.07-4.85.07a83 83 0 01-4.85-.07c-3.26-.15-4.77-1.7-4.92-4.92A82.98 82.98 0 012.16 12a84 84 0 01.07-4.85c.15-3.23 1.67-4.77 4.92-4.92A84.4 84.4 0 0112 2.16zM12 0C8.74 0 8.33.01 7.05.07 2.7.27.27 2.7.07 7.05A84.29 84.29 0 000 12c0 3.26.01 3.67.07 4.95.2 4.36 2.62 6.78 6.98 6.98 1.28.06 1.7.07 4.95.07 3.26 0 3.67-.01 4.95-.07 4.35-.2 6.78-2.62 6.98-6.98.06-1.28.07-1.7.07-4.95 0-3.26-.01-3.67-.07-4.95C23.73 2.7 21.3.27 16.95.07A84.33 84.33 0 0012 0zm0 5.84a6.16 6.16 0 100 12.32 6.16 6.16 0 000-12.32zM12 16a4 4 0 110-8 4 4 0 010 8zm6.4-11.84a1.44 1.44 0 100 2.88 1.44 1.44 0 000-2.88z" fill="currentColor" />
      </g>)
      break;
    case "linkedin":
      g = (<g>
        <path d="M20.45 20.45h-3.56v-5.57c0-1.33-.02-3.04-1.85-3.04-1.85 0-2.13 1.45-2.13 2.94v5.67H9.35V9h3.42v1.56h.04a3.74 3.74 0 013.37-1.85c3.6 0 4.27 2.37 4.27 5.46zM5.34 7.43a2.06 2.06 0 110-4.13 2.06 2.06 0 010 4.13m1.78 13.02H3.56V9h3.56zM22.22 0H1.77A1.75 1.75 0 000 1.73v20.54C.01 23.23.8 24 1.77 24h20.45c.97.01 1.77-.76 1.78-1.73V1.73A1.76 1.76 0 0022.22 0" fill="currentColor" />
      </g>)
      break;
    case "twitter":
      g = (<g>
        <path d="M24 4.6c-.9.3-1.8.6-2.8.7a5 5 0 002.1-2.7c-1 .6-2 1-3 1.2a5 5 0 00-8.5 4.5A14 14 0 011.7 3a5 5 0 001.5 6.6c-.8 0-1.6-.2-2.2-.6A5 5 0 005 14a5 5 0 01-2.3 0 5 5 0 004.6 3.5 9.9 9.9 0 01-7.3 2A14 14 0 0021.5 7.1 10 10 0 0024 4.6z" fill="currentColor" />
      </g>)
      break;
    case "mail":
      g = (<g fill="currentColor">
        <path d="M20 4H4L2 6v12l2 2h16l2-2V6l-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </g>)
      break;
    case "code/code":
      g = (<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M17.2718029,8.68536757 C16.8932864,8.28319382 16.9124644,7.65031935 17.3146382,7.27180288 C17.7168119,6.89328641 18.3496864,6.91246442 18.7282029,7.31463817 L22.7282029,11.5646382 C23.0906029,11.9496882 23.0906029,12.5503176 22.7282029,12.9353676 L18.7282029,17.1853676 C18.3496864,17.5875413 17.7168119,17.6067193 17.3146382,17.2282029 C16.9124644,16.8496864 16.8932864,16.2168119 17.2718029,15.8146382 L20.6267538,12.2500029 L17.2718029,8.68536757 Z M6.72819712,8.6853647 L3.37324625,12.25 L6.72819712,15.8146353 C7.10671359,16.2168091 7.08753558,16.8496835 6.68536183,17.2282 C6.28318808,17.6067165 5.65031361,17.5875384 5.27179713,17.1853647 L1.27179713,12.9353647 C0.909397125,12.5503147 0.909397125,11.9496853 1.27179713,11.5646353 L5.27179713,7.3146353 C5.65031361,6.91246155 6.28318808,6.89328354 6.68536183,7.27180001 C7.08753558,7.65031648 7.10671359,8.28319095 6.72819712,8.6853647 Z" fill="#335EEA"></path>
        <rect fill="#335EEA" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-345.000000) translate(-12.000000, -12.000000) " x="11" y="4" width="2" height="16" rx="1"></rect>
      </g>)
      break;
    case "general/settings-1":
      g = (<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z" fill="#335EEA"></path>
        <path d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z" fill="#335EEA" opacity="0.3"></path>
      </g>)
      break;
    case "layout/layout-arrange":
      g = (<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M5.5,4 L9.5,4 C10.3284271,4 11,4.67157288 11,5.5 L11,6.5 C11,7.32842712 10.3284271,8 9.5,8 L5.5,8 C4.67157288,8 4,7.32842712 4,6.5 L4,5.5 C4,4.67157288 4.67157288,4 5.5,4 Z M14.5,16 L18.5,16 C19.3284271,16 20,16.6715729 20,17.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,17.5 C13,16.6715729 13.6715729,16 14.5,16 Z" fill="#335EEA"></path>
        <path d="M5.5,10 L9.5,10 C10.3284271,10 11,10.6715729 11,11.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,12.5 C20,13.3284271 19.3284271,14 18.5,14 L14.5,14 C13.6715729,14 13,13.3284271 13,12.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z" fill="#335EEA" opacity="0.3"></path>
      </g>)
      break;
    default:
      break;
  }

  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      {g}
    </svg>
  )
}

export default Icon