module.exports = {
  search: "Search",
  publishedOn: "Published on",
  share: "Share",
  blogViewAll: "View all",
  blogOverviewHeadline: "Our newsroom",
  blogOverviewSubline: "Keep up to date with what we're working on! Landkit is an ever evolving theme with regular updates.",
  blogTeaserSubline: "Always keep up to date with what we're working on",
  popularPostsHeadline: "Popular Stories",
  popularPostsSubline: "Here’s what’s big in the past week!",
  contactTitle: "Contact",
  contactSubline: "Our office hours are from Monday through Thursday between 09:30 a.m. to 5:30 p.m. and Friday to 5:00 p.m. For more information about Est8 Services and how our services can enhance your business, please contact us.",
  contactLabelAddress: "Visit us",
  contactLabelTelephone: "Call us",
  contactLabelEmail: "Write us",
  contactFormTitle: "Leave a message",
  contactFormSubline: "We are happy if you leave us a message here. We will contact you promptly!",
  contactFormName: "Your name",
  contactFormNamePlaceholder: " ",
  contactFormCompany: "Company",
  contactFormCompanyPlaceholder: " ",
  contactFormEmail: "Email",
  contactFormEmailPlaceholder: " ",
  contactFormTelephone: "Telephone",
  contactFormTelephonePlaceholder: " ",
  contactFormMessage: "Your message",
  contactFormMessagePlaceholder: "Tell us what we can help you with!",
  contactFormButton: "Send message",
  notFound404: "Page not found",
  notFound404Title: "Uh oh… 😬",
  notFound404Subline: "The requested page does not exist!",
  notFound404Homelink: "Explore the home page",
  cookieText: "This site uses cookies to enhance your experience",
  cookieButtonAccept: "Accept",
  cookieButtonDecline: "Decline"
};