import React from 'react'

import IntlWrapper from '../intl/intl-wrapper';

import NavBar from './navbar'
import Footer from './footer'
import CookieBar from './cookie-bar';

import "../scss/theme.scss"

const Layout = ({ children, location, pageContext: { links } }) => (
  <IntlWrapper location={location}>
    <div className="min-vh-100 d-flex flex-column">
      <NavBar type="fluid" links={links} />
      <main className="flex-grow-1">
        {children}
      </main>
      <Footer />
      <CookieBar />
    </div>
  </IntlWrapper>
)

export default Layout