module.exports = {
  // Make sure that every slug begins with a '/'
  slugify: function (slug) {
    if (slug === "/" || slug === null) {
      return ""
    }
    return slug.indexOf("/") === 0 ? slug : `/${slug}`
  },
  validateExternalLink: function (url) {
    return /(((mailto|tel):\w+)|((http|https|ftp):\/\/\w+)).+/g.test(url)
  },
}
