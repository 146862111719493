import React from 'react'
import { useStaticQuery, graphql } from 'gatsby';

import { slugify } from '../utils'
import Link from './link'
import Icon from './icon'
import { useIntl } from 'react-intl';

import bviSiegel from '../img/bvi-guetesiegel-gastmitglied.jpg'

const Footer = () => {

  const { locale } = useIntl()
  const data = useStaticQuery(query)
  const contentfulPages = data.allContentfulPageTextContent.nodes

  const navOrder = [
    {
      de: {
        slug: "/de/kontakt",
        title: "Kontakt"
      },
      en: {
        slug: "/en/contact",
        title: "Contact"
      }
    },
    "6CNjoCcmAuudp5YUWcuxNk",   // Datenschutz
    "6wSnEBRO2P5hLteIQQHTg7"    // Impressum
  ]

  // combine hard-coded links and contentful-links to a new array, each filtered to the current locale
  const navData = navOrder.reduce((nav, el) => {
    if (typeof el === "object") {
      nav.push(el[locale])
    } else if (typeof el === "string") {
      const page = contentfulPages.filter(page => page.node_locale === locale && page.contentful_id === el)[0]
      nav.push({
        slug: `${slugify(locale)}${slugify(page.slug)}`,
        title: page.title
      })
    }
    return nav
  }, [])

  const socialLinks = [
    { icon: "twitter", link: "https://twitter.com/est8services", alt: "Twitter Icon" },
    { icon: "facebook", link: "https://facebook.com/est8services", alt: "Facebook Icon" },
    { icon: "linkedin", link: "https://www.linkedin.com/company/est8-services/", alt: "Linkedin Icon" }
  ]

  return (
    <footer className="my-6 my-sm-8">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-auto col-lg-5 d-flex flex-column justify-content-between align-items-center align-items-sm-start">
            <ul className="list-unstyled text-muted my-0">
              <li className="text-center text-sm-left my-3 my-sm-1">
                ©&#8239;{new Date().getFullYear()} Est8 Services GmbH
              </li>
            </ul>
            <ul className="list-unstyled list-inline list-social my-6 my-lg-2">
              {socialLinks.map((social, i) => (
                <li className="list-inline-item list-social-item px-2 px-lg-0 pr-lg-4" key={`${social.link}-${i}`}>
                  <a href={social.link} className="text-muted" target="_blank" rel="noopener noreferrer">
                    <Icon id={social.icon} alt={social.alt} width="24" height="24" />
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-12 col-sm-auto col-lg-4">
            <ul className="row list-unstyled text-muted flex-column justify-content-center justify-content-sm-between my-0">
              {navData.map(link => (
                <li className="col-12 text-center text-sm-left col-sm-auto my-3 my-sm-1" key={`${link.id}-${link.slug}`}>
                  <Link to={link.slug} className="text-reset">
                    {link.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-12 col-sm-auto col-lg-3 text-center mx-sm-auto my-5 my-md-0">
            <img className="footer__siegel" src={bviSiegel} alt="EST8 ist Gastmitglied im BVI (Bundesverband der Immobilienverwalter e.V.)" />
          </div>

        </div>
      </div>
    </footer>
  )
}

const query = graphql`
  {
    allContentfulPageTextContent(filter: {
      contentful_id: { in: [
        "6CNjoCcmAuudp5YUWcuxNk",
        "6wSnEBRO2P5hLteIQQHTg7"
      ]
    }}) {
        nodes {
        id
        title
        slug
        contentful_id
        node_locale
      }
    }
  }
`

export default Footer