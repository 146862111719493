import React from 'react'
import CookieConsent from 'react-cookie-consent';
import { useIntl, FormattedMessage } from 'react-intl'

const CookieBar = () => {
  const intl = useIntl()

  return (
    <CookieConsent
      disableStyles
      containerClasses="cookie-consent"
      buttonClasses="btn btn-small btn-primary"
      declineButtonClasses="btn btn-small btn-secondary-soft"
      contentClasses="cookie-consent__text"
      enableDeclineButton
      buttonText={intl.formatMessage({ id: "cookieButtonAccept" })}
      declineButtonText={intl.formatMessage({ id: "cookieButtonDecline" })}
      cookieName="gatsby-gdpr-google-tagmanager"
    >
      <FormattedMessage id="cookieText" />
    </CookieConsent>
  )
}

export default CookieBar