import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import Link from 'gatsby-link'
import { motion } from 'framer-motion'

import { useWindowSize } from '../utils/useWindowSize'
import { useIntl } from 'react-intl';

const SelectLanguage = ({ links, ...props }) => {

  const intl = useIntl()

  const data = useStaticQuery(query)
  const { langs } = data.site.siteMetadata.languages

  const langsMenu = langs.map(lang => ({
    langKey: lang,
    selected: lang === intl.locale,
    link: links ? links[lang] : ""
  }))

  const { width } = useWindowSize()
  const screen = width > 992 ? "desktop" : "mobile"

  return (
    <div className="select-language" {...props}>

      {langsMenu.map(lang => (
        <Fragment key={lang.langKey}>

          {lang.selected
            ? (
              <span className="knob knob-selected">
                <motion.div
                  layoutId={`knob-bg-${screen}`}
                  className={`knob-bg knob-bg-${screen}`}
                />
                {lang.langKey.toUpperCase()}
              </span>
            )
            : (
              <Link to={lang.link} className="knob">
                {lang.langKey.toUpperCase()}
              </Link>
            )
          }

        </Fragment>
      ))}
    </div>
  )
}

const query = graphql`
  query {
    site {
      siteMetadata {
        languages {
          langs
        }
      }
    }
  }
`

export default SelectLanguage