module.exports = {
  search: "Suche",
  publishedOn: "Veröffentlicht am",
  share: "Teilen",
  blogViewAll: "Alle Artikel",
  blogOverviewHeadline: "Neuigkeiten von uns",
  blogOverviewSubline: "Bleiben Sie immer informiert über aktuelle Geschehnisse auf dem Berliner Wohnungsmarkt",
  blogTeaserSubline: "Bleiben Sie jederzeit bestens informiert",
  popularPostsHeadline: "Beliebte Beiträge",
  popularPostsSubline: "Das interessiert Wohnungseigentümer in Berlin",
  contactTitle: "Kontakt",
  contactSubline: "Das Team von Est8 Services steht Ihnen Montag bis Donnerstag von 09:00h bis 17:30h, Freitag bis 17:00h sowie nach individueller Terminvereinbarung zur Verfügung.",
  contactLabelAddress: "Besuchen",
  contactLabelTelephone: "Anrufen",
  contactLabelEmail: "Schreiben",
  contactFormTitle: "Hinterlassen Sie eine Nachricht",
  contactFormSubline: "Wir freuen uns, wenn Sie uns hier eine Nachricht hinterlassen. Wir setzen uns zeitnah mit Ihnen in Verbindung!",
  contactFormName: "Ihr Name",
  contactFormNamePlaceholder: " ",
  contactFormCompany: "Unternehmen",
  contactFormCompanyPlaceholder: " ",
  contactFormEmail: "E-Mail",
  contactFormEmailPlaceholder: " ",
  contactFormTelephone: "Telefon",
  contactFormTelephonePlaceholder: " ",
  contactFormMessage: "Ihre Nachricht",
  contactFormMessagePlaceholder: "Teilen Sie uns mit, wie wir Ihnen helfen können!",
  contactFormButton: "Nachricht senden",
  notFound404: "Seite nicht gefunden",
  notFound404Title: "Oh, oh… 😬",
  notFound404Subline: "Die verlinkte Seite existiert leider nicht!",
  notFound404Homelink: "Zur Startseite",
  cookieText: "Diese Seite nutzt Cookies, um Ihr Nutzererlerbnis kontinuierlich zu verbessern",
  cookieButtonAccept: "Akzeptieren",
  cookieButtonDecline: "Ablehnen"
};