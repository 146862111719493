module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Founders Grotesk","Feather"],"urls":["/fonts/fonts.css","/fonts/Feather/feather.css"]},"google":{"families":["Inter:400,700"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-T53SD2W","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},"environments":["production"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
