import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getCurrentLangKey } from 'ptz-i18n';
import { IntlProvider } from 'react-intl';
import 'intl';

const IntlWrapper = ({ children, location }) => {

  const data = useStaticQuery(query)
  const resourceData = data.allContentfulResource.nodes

  const url = location.pathname;
  const { langs, defaultLangKey } = data.site.siteMetadata.languages;
  const langKey = getCurrentLangKey(langs, defaultLangKey, url);

  /* 
    load microcopy from ./src/data/messages/ 
    and add/overwrite with Resources from Contentful
    ¯\_(ツ)_/¯
  */
  const i18nMessages = require(`./messages/${langKey}`);
  resourceData.filter(r => r.node_locale === langKey).reduce((set, r) => {
    set[r.key] = r.value.value
    return set
  }, i18nMessages)

  return (
    <IntlProvider locale={langKey} messages={i18nMessages} key={url}>
      {children}
    </IntlProvider>
  )
}

const query = graphql`
  {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    allContentfulResource {
      nodes {
        contentful_id
        node_locale
        key
        value {
          value
        }
      }
    }
  }
`

export default IntlWrapper